import {useCallback, useState} from 'react';
import {useComponentForQueryResult} from 'src/hooks/UseComponentForQueryResult';
import {getMailForwards} from 'src/services/shared/MailForward';
import {cacheKeys} from 'src/services/shared/serviceConstants';
import {BusinessEmailComponent} from 'src/pages/BusinessEmailPage/BusinessEmailComponent';
import {useQueryWithAccessToken} from "src/hooks/TanstackHooks";

// handles data aquisition and decides whether to
// return the page, loading or error component
export const BusinessEmailPage = () => {
  const documentTitle = 'Business Email - Detexian';
  document.title = documentTitle;

  const [userErrorMessage, setUserErrorMessge] = useState('');

  const externalForwardsQueryResult = useQueryWithAccessToken({
      queryKey: [cacheKeys.externalForwards],
      queryFunction: getMailForwards,
      queryFunctionVariables: {isExternal: true},
      tanstackOptions: {
        onError: () => {
          setUserErrorMessge('error accessing external forwards');
        }
      }
    }
  );

  const internalForwardsQueryResult = useQueryWithAccessToken({
      queryKey: [cacheKeys.internalForwards],
      queryFunction: getMailForwards,
      queryFunctionVariables: {isExternal: false},
      tanstackOptions: {
        onError: () => {
          setUserErrorMessge('error accessing internal forwards');
        }
      }
    }
  );

  // builds the successful page for the hook below
  const pageLoadFunction = useCallback(
    () =>
      <BusinessEmailComponent
        externalForwards={externalForwardsQueryResult.data}
        internalForwards={internalForwardsQueryResult.data}
      />
    ,
    [externalForwardsQueryResult.data, internalForwardsQueryResult.data]
  );

  return useComponentForQueryResult({
    queryResult: externalForwardsQueryResult,
    pageLoadFunction,
    userErrorMessage,
    errorMessageDoesIndicateError: true
  })
};
