import React from 'react';
import {useSearchParams} from 'react-router-dom';
import {UserActionTable} from 'src/components/common/Tables';
import {DtxSpacer} from 'src/components/common/DtxSpacer';
import {riskTypeIdentifiers, riskTypeToDisplay, singlePanelUrl} from 'src/routes';

type props = {
  externalForwards: any;
  internalForwards: any;
}

export const BusinessEmailComponent = ({
  externalForwards,
  internalForwards,
}: props) => {

  const [searchParams] = useSearchParams();

  const panel = (users: string | any[], heading: any, riskType: any) => {
    return <> {
      users?.length > 0 && (
        <UserActionTable
          users={users}
          shouldShowSaasColumn={true}
          shouldShowSharedToColumn={true}
          sharedToColumnLabel="Forwarded To"
          heading={`${heading} (${users?.length})`}
          singlePanelUrl={
            singlePanelUrl(
            searchParams,
            riskType,
            users?.length
          )}
          dateColumnLabel="Detected"
        />
      )
    }</>
  }

  const externalForwardsPanel = panel(
    externalForwards,
    'External Mail Forwards',
    riskTypeIdentifiers.externalForwards,
  )

  const internalForwardsPanel = panel(
    internalForwards,
    'Internal Mail Forwards',
    riskTypeIdentifiers.internalForwards
  )


  const allPanels = () => {
    return <>
      {externalForwardsPanel}
      <DtxSpacer />
      {internalForwardsPanel}
    </>
  };

  // Not the easiest code to understand.
  //
  // The shared function riskTypeToDisplay returns a url
  // query-string fragment rom the current url - the fragment
  // with the key "risk".  This function compares that
  // fragment with values in the riskTypeIdentifiers 'enum'
  // to pick the panel to display in full-page mode -
  // or display all panels in compact mode.
  const panelToDisplay = () => {
    let result = allPanels()
    switch(riskTypeToDisplay(searchParams)) {
      case riskTypeIdentifiers.externalForwards:
        result = externalForwardsPanel;
        break;
      case riskTypeIdentifiers.internalForwards:
        result = internalForwardsPanel;
        break;
    }
    return result;
  }

  return panelToDisplay()
};
